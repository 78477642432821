<template>
    <div class="container about" >
        <div class="row d-flex align-items-start mt-4" style="max-width: inherit;">
            <div class="d-flex justify-content-between" style="overflow: scroll;">
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/8w0nSV7UjMA?si=LcwSTUxvG8paPCEf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-lg-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/gm-hKgfZOek?si=S3Sv3i7NfDs3mEp3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-lg-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/9OIW0ZZhgVs?si=Ft7aJMZQzDHWRGGE" title="YouTube video player" frameborder="0" allow="accelerometer; 200play; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/XgFjrrgXfTE?si=md5tX4gohOgSBp68" title="YouTube video player" frameborder="0" allow="accelerometer; 200play; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/06t-hFiIJ2M?si=7Nt5SNgssZ71AmEn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/_1Bh7ksQWps?si=lJwFXvCDGhui9tN2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/Ssw4fs5nhn0?si=WMFuN00e7Cj0-AVT" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
                <div class="Col-12 col-lg-4 m-2 m-md-3">
                    <iframe width="450" height="250" src="https://www.youtube.com/embed/1_ciOQNSSUo?si=G8pLcbQIAVrP8A5K" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "Videos",
    mounted() {

    },
    methods: {
    }
}
</script>

<style scoped>
/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content {
    position: relative;
    z-index: 0;
}

.about .content h3 {
    font-weight: 600;
    font-size: 26px;
}

.about .content ul {
    list-style: none;
    padding: 0;
}

.about .content ul li {
    padding-left: 28px;
    position: relative;
}

.about .content ul li+li {
    margin-top: 10px;
}

.about .content ul i {
    position: absolute;
    left: 0;
    top: 2px;
    font-size: 20px;
    color: #3db49d;
    line-height: 1;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .btn-learn-more {
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    text-decoration: none;
    display: inline-block;
    padding: 12px 32px;
    border-radius: 5px;
    transition: 0.3s;
    line-height: 1;
    color: #3db49d;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
    margin-top: 6px;
    border: 2px solid #3db49d;
}

.about .content .btn-learn-more:hover {
    background: #3db49d;
    color: #fff;
}

/*--------------------------------------------------------------
# Cards
--------------------------------------------------------------*/

.cards {
    color: #2a2c39;
}

.cards .member {
    background-color: #2a2c39;
    border-radius: 5px;
    width: 25rem;
    height: 150px;
}

.cards .member .member-info {
    padding: 25px 15px;
    color: #fff;
}

.cards .member .member-info h4 {
    font-weight: 500;
    margin-bottom: 5px;
    font-family: "Poppins";
    font-size: 40px;
}

.cards .member .member-info .anchor {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    width: auto;
    height: 100%;
    background: #3db49d;
    border-radius: 5px;
    transition: cubic-bezier(0.04, 0.28, 0.58, 1) 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cards .member:hover .anchor {
    opacity: 1;
}

.cards .member .member-info .anchor li{
    font-weight: bolder;
}

.cards .member .member-info span {
    display: block;
    font-size: 16px;
    font-weight: 400;
}
</style>